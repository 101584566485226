import PropTypes from "prop-types";
import classNames from "classnames";
import useBackInStock from "../../hooks/useBackInStock";
import { useVariant } from "../../context/variant/VariantContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import Button from "../buttons/button/Button";
import { BUTTON_TYPES } from "../buttons/button/ButtonTypes";
import { BACK_IN_STOCK_BUTTON_ID, TEXT_SIZES } from "../../constants";
import styles from "./NotificationButton.scss";
import {
  ignoreBackInStockAnalytics,
  setBackInStockAnalytics,
} from "../../analytics/postPageLoad/backInStock";
import { useProduct } from "../../context/product/ProductContext";
import isRestockingSoon from "../../utils/restockingSoon";
import { getCustomerLoyaltyAccess } from "../../utils/getCustomerLoyaltyAccess";
import { shouldRenderButton } from "./shouldRenderButton";

const isKnownToBeInStock = ({ isInStock, selectedVariant }) =>
  (isInStock && !selectedVariant) || selectedVariant?.isInStock;

const createButton = ({
  iconClass,
  text,
  type,
  onClick,
  inStockProduct,
  shouldShowSave,
  shouldShowRestockingSoon,
  isLooksProduct,
  isMobile,
}) => {
  const hasSaveButton =
    shouldShowSave && (inStockProduct || shouldShowRestockingSoon);

  const isDesktopLooks = !isMobile && isLooksProduct;

  return (
    <Button
      type={type}
      id={BACK_IN_STOCK_BUTTON_ID}
      handleClick={onClick}
      size={isDesktopLooks ? TEXT_SIZES.london3 : TEXT_SIZES.london2}
      additionalClasses={classNames(styles.button, {
        [styles.desktopLooks]: isDesktopLooks,
        [styles.withSaveButton]: hasSaveButton,
      })}
    >
      <span className={classNames(iconClass, styles.icon)}></span>
      <span className={styles.text}>{text}</span>
    </Button>
  );
};

const NotificationButton = ({
  handleNotifyMeClick,
  isInStock,
  shouldShowSave,
  selectedProductId,
  isLooksProduct,
}) => {
  const { selectedVariant } = useVariant();
  const {
    hasNotification,
    addVariantToNotifications,
    removeVariantFromNotifications,
  } = useBackInStock();
  const selectedVariantId = selectedVariant?.variantId;
  const { getTranslation, oosVariantsAreSelectable, isMobile } =
    useRuntimeConstants();
  const variantHasNotification = hasNotification(selectedVariantId);
  const { product } = useProduct();
  const { customerAccess } = getCustomerLoyaltyAccess(product);

  if (
    !shouldRenderButton({ customerAccess, isInStock, oosVariantsAreSelectable })
  ) {
    return null;
  }

  if (isKnownToBeInStock({ isInStock, selectedVariant })) {
    ignoreBackInStockAnalytics();
    return null;
  }

  const commonParams = {
    inStockProduct: isInStock,
    shouldShowSave,
    isLooksProduct,
    isMobile,
    shouldShowRestockingSoon: isRestockingSoon(product),
  };

  if (variantHasNotification) {
    ignoreBackInStockAnalytics();
    return createButton({
      ...commonParams,
      type: BUTTON_TYPES.tertiary,
      onClick: () => {
        removeVariantFromNotifications({ variantId: selectedVariantId });
      },
      text: getTranslation("back_in_stock_disable_alert"),
      iconClass: "product-email",
    });
  }

  setBackInStockAnalytics();

  return createButton({
    ...commonParams,
    type: BUTTON_TYPES.primary,
    onClick: () => {
      handleNotifyMeClick();
      if (selectedVariantId) {
        addVariantToNotifications({
          variantId: selectedVariantId,
          productId: selectedProductId,
        });
      }
    },
    text: getTranslation("back_in_stock_notify_me"),
    iconClass: "product-email-white",
  });
};

NotificationButton.propTypes = {
  handleNotifyMeClick: PropTypes.func.isRequired,
  isInStock: PropTypes.bool.isRequired,
  shouldShowSave: PropTypes.bool.isRequired,
  selectedProductId: PropTypes.number.isRequired,
  isLooksProduct: PropTypes.bool.isRequired,
};

export default NotificationButton;

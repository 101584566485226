import { useRef } from "react";
import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { getTranslatedText } from "./getTranslatedText";
import { getCustomerLoyaltyAccess } from "../../utils/getCustomerLoyaltyAccess";
import { BULLET_SYMBOL } from "../../constants";
import styles from "./RepeatedTextBanner.scss";

const classnames = classnamesBind.bind(styles);

const RepeatedTextBanner = ({ className }) => {
  const bannerRef = useRef(null);
  const textRef = useRef(null);
  const { getTranslation, product } = useRuntimeConstants();
  const { customerAccess } = getCustomerLoyaltyAccess(product);
  const textToRender = getTranslatedText(getTranslation, customerAccess);
  const repetitions = 10;

  if (!textToRender) {
    return null;
  }

  const repeatedText = `${textToRender}${BULLET_SYMBOL}`;

  const accessClass = customerAccess ? `access-${customerAccess}` : "";

  return (
    <div
      ref={bannerRef}
      className={classnames(
        styles.repeatedTextBanner,
        styles[accessClass],
        className
      )}
    >
      <span ref={textRef} className={styles.bannerText}>
        {repeatedText.repeat(repetitions)}
      </span>
    </div>
  );
};

RepeatedTextBanner.propTypes = {
  className: PropTypes.string,
};

export default RepeatedTextBanner;
